import axios from 'axios';
import moment from 'moment';
import momenttz from 'moment-timezone'
import * as config from '../config';

export const SETISLOADINGINSIGHTS = 'insights/SETLOADINGINSIGHTS';

export const getSiteInsights = ({sites = [], locations = [], devices = [], start_time = moment().subtract(1, 'weeks').format('YYYY-MM-DD HH:mm:ss'), end_time = moment().format('YYYY-MM-DD HH:mm:ss')}, onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {
    let timezone = momenttz.tz.guess()
    
    let formBox = convertToForm(sites,locations,devices,start_time,end_time,timezone)

    dispatch({type: SETISLOADINGINSIGHTS, isLoadingInsights: true});

    axios({
      method: 'POST',
      url: `${config.endpoints.base_url_insights}v2/insights`,
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: formBox
    }).then(resp => {
      dispatch({type: SETISLOADINGINSIGHTS, isLoadingInsights: false});
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err)
    })

  };
}

export const SETISLOADINGDEMOGRAPHICS = 'insights/SETISLOADINGDEMOGRAPHICS';

export const getSiteDemographics = ({sites = [], locations = [], devices = [], start_time = moment().subtract(1, 'weeks').format('YYYY-MM-DD HH:mm:ss'), end_time = moment().format('YYYY-MM-DD HH:mm:ss')}, onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {
    let timezone = momenttz.tz.guess()

    let formBox = convertToForm(sites,locations,devices,start_time,end_time,timezone)

    dispatch({type: SETISLOADINGDEMOGRAPHICS, isLoadingDemographics: true});
    axios({
      method: 'POST',
      url: `${config.endpoints.base_url_insights}v2/demographics`,
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: formBox
    }).then(resp => {
      dispatch({type: SETISLOADINGDEMOGRAPHICS, isLoadingDemographics: false});
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err)
    })
  };
}

export const exportInsights = ({sites = [], locations = [], start_time = moment().subtract(1, 'weeks').format('YYYY-MM-DD HH:mm:ss'), end_time = moment().format('YYYY-MM-DD HH:mm:ss')}, onSuccess = () => {}, onError = () => {}) => {

  let sitesQueryParams = '';
  if(sites.length > 0) {
    sites.forEach((site, siteIDX) => {
      if(siteIDX > 0) {
        sitesQueryParams += '&';
      }
      sitesQueryParams += `sites[]=${site}`
    });
  } else {
    sitesQueryParams += `&sites[]=`;
  }

  let locationsQueryParams = '';
  if(sites.length > 0) {
    locations.forEach((location, locationIDX) => {
      if(locationIDX >= 0) {
        locationsQueryParams += '&';
      }
      locationsQueryParams += `locations[]=${location}`
    });
  } else {
    sitesQueryParams += `&locations[]=`;
  }

  return (dispatch, getState) => {
    axios(`${config.endpoints.base_url_insights}v2/exports?${sitesQueryParams}${locationsQueryParams}&start_time=${start_time}&end_time=${end_time}`).then((resp) => {
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    })
  }
}

export const getPowerBIEmbedToken = (onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {
    axios.post(`${config.endpoints.base_url}auth/embedToken`).then((resp) => {
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    })
  }
};

export const createUXBullshitMarkup = (dateString) => {
  const formattedDateString = dateString
    .replace('s', '<sup>s</sup>')
    .replace('m', '<sup>m</sup>')
    .replace('h', '<sup>h</sup>');
  return { __html: formattedDateString };
};

export const getDeviceInsights = ({devices = [], start_time = moment().subtract(1, 'weeks').format('YYYY-MM-DD HH:mm:ss'), end_time = moment().format('YYYY-MM-DD HH:mm:ss')}, onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {
    let timezone = momenttz.tz.guess()
    let formBox = convertToForm([],[],devices,start_time,end_time,timezone)

    dispatch({type: SETISLOADINGINSIGHTS, isLoadingInsights: true});

    axios({
      method: 'POST',
      url: `${config.endpoints.base_url_insights}v2/insights`,
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: formBox
    }).then(resp => {
      dispatch({type: SETISLOADINGINSIGHTS, isLoadingInsights: false});
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err)
    })

  };
}

export const getDeviceDemographics = ({devices = [], start_time = moment().subtract(1, 'weeks').format('YYYY-MM-DD HH:mm:ss'), end_time = moment().format('YYYY-MM-DD HH:mm:ss')}, onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {
    let timezone = momenttz.tz.guess()

    let formBox = convertToForm([],[],devices,start_time,end_time,timezone)
   
    dispatch({type: SETISLOADINGDEMOGRAPHICS, isLoadingDemographics: true});

    axios({
      method: 'POST',
      url: `${config.endpoints.base_url_insights}v2/demographics`,
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: formBox
    }).then(resp => {
      dispatch({type: SETISLOADINGDEMOGRAPHICS, isLoadingDemographics: false});
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err)
    })
    
  };
}

const convertToForm = (sites,locations,devices,start_time,end_time, timezone) => {
  let formBox = new URLSearchParams()
  if(sites.length > 0) {
    sites.forEach((site) => {
      formBox.append('sites',site)
    });
  }

  if(sites.length > 0) {
    locations.forEach((location, locationIDX) => {
      formBox.append('locations',location)
    });
  } 

  if(devices.length > 0) {
    devices.forEach((device, deviceIDX) => {
      formBox.append('devices',device)
    });
  }

  formBox.append('start_time', start_time)
  formBox.append('end_time', end_time)
  formBox.append('timezone', timezone)

  return formBox;
 
}