import { SETISLOADINGDEVICES, SETDEVICES, SETISEDITINGDEVICE } from '../actions/devices';

const initialState = {
  isLoadingDevices: false,
  devices: [],
  isEditingDevice: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SETISLOADINGDEVICES:
      return {
        ...state,
        isLoadingDevices: action.isLoadingDevices
      }
    case SETDEVICES:
      return {
        ...state,
        devices: action.devices
      }
    case SETISEDITINGDEVICE:
      return {
        ...state,
        isEditingDevice: action.isEditingDevice
      }
    default:
      return state;
  }
}
