import jwt_decode from 'jwt-decode';
import axios from 'axios';
import qs from 'qs';
import * as config from '../config';
import definedRoles from '../constants/roles.json';
import vianaBentoIcon from '../assets/img/viana-hex.png';
// import vianalogo from '../../assets/img/viana-hex.png';

import { getMyNetwork } from './networks';

export const getID = () => {
  const token = localStorage.getItem('access_token');
  return jwt_decode(token).sub;
};

export const getUserName = () => {
  const token = localStorage.getItem('access_token');
  return jwt_decode(token).preferred_username;
};

export const getGivenName = () => {
  const token = localStorage.getItem('access_token');
  return jwt_decode(token).given_name;
};

export const getFamilyName = () => {
  const token = localStorage.getItem('access_token');
  return jwt_decode(token).family_name;
}

export const getEmail = () => {
  const token = localStorage.getItem('access_token');
  return jwt_decode(token).email;
};

export const getNetworkName = () => {
  const token = localStorage.getItem('access_token');
  return jwt_decode(token).networks;
};

export const getTenant = () => {
  const token = localStorage.getItem('access_token');
  return jwt_decode(token).tenant;
};

export const getUserRoles = () => {
  const token = localStorage.getItem('access_token');
  return jwt_decode(token).resource_access;
};

export const getBentoItems = (network) => {
  let { networkapps } = network;
  let bentoItems = [{
    source: config.endpoints?.viana_url, // will refresh page
    name: 'Viana',
    imageUrl: vianaBentoIcon,
  }];

  let apps = networkapps && networkapps.length > 0 ? networkapps.map(s => s.app) : [];
  if (apps.length > 0) {
    apps.filter(app => app?.config?.category === 'bento').forEach((item) => {
      bentoItems.push({
        source: item.source,
        name: item.name,
        imageUrl: item.config.logo || 'https://via.placeholder.com/150',
      })
    });
  }

  return bentoItems
}

export const getCurrentNetworkDetails = async (network) => {
  const getMyNetworkResp = await getMyNetwork()
  let currentNetworkDetails = '';
  getMyNetworkResp.data.map((network)=>{
    if(network.name == localStorage.getItem('currentNetwork')){
      currentNetworkDetails = network
    }
  });
  
  return currentNetworkDetails
}

export const getUserRole = () => {

  let userRole = '';
  const accessToken = localStorage.getItem('access_token');
  if (accessToken !== undefined) {
    let auth = null
    try {
      auth = jwt_decode(accessToken);
    }
    catch (e) {
      window.location.reload();
    }
    
    try {
      let currentNetwork = localStorage.getItem('currentNetwork');
      let doesExist = false;
      if(Array.isArray(auth.aud)){
        auth.aud.map((network)=>{
          if(network == currentNetwork && !doesExist){
            doesExist = true;
          }
        })
      }
      
      if(!doesExist){
        currentNetwork = null
      }

      if(currentNetwork == null || currentNetwork == '' || currentNetwork == 'undefined'){
        if(Array.isArray(auth.aud)){
          let networkSelected = false;
          auth.aud.map((network)=>{
            if(network != 'account' && !networkSelected){
              networkSelected = true;
              currentNetwork = network
              localStorage.setItem('currentNetwork', network);
            }
          })
        }
      } 
    
      let resourceAccess = auth.resource_access[currentNetwork];
  
      if(resourceAccess != undefined){
        const { roles } = resourceAccess;
        userRole = roles.find(role => {
          return Object.values(definedRoles).map(definedRole => definedRole === role)
        })
      } else {// if no resource access found use real access to avoid errors
        const { realm_access } = auth;
        const { roles } = realm_access;
        userRole = roles.find(role => {
          return Object.values(definedRoles).map(definedRole => definedRole === role)
        })
      }
      
    } catch (error) { console.log(error)}
   
  }

  return userRole;

};

export const getUserRolesV2 = () => { 
  let userRoles = [];
  const accessToken = localStorage.getItem('access_token');

  if (accessToken !== undefined) {
    let auth = null
    try {
      auth = jwt_decode(accessToken);
    }
    catch (e) {
      window.location.reload();
    }

    try {
      let currentNetwork = localStorage.getItem('currentNetwork');
      let resourceAccess = auth.resource_access[currentNetwork];
      if(resourceAccess != undefined){
        const { roles } = resourceAccess;
        userRoles = roles.map(r => ({ name: r }));
      }
      
    } catch (error) { console.log(error)}
  }
 
  return userRoles;
};

export const getUserRolesName = () => { 

  // Getting access token from local storage
  let userRoles = []
  const accessToken = localStorage.getItem('access_token');

  if (accessToken !== undefined) {
    let auth = {
      'aud':[],
      'resource_access':{}
    }

    try {
      auth = jwt_decode(accessToken);
    }catch (e) {
      window.location.reload();
    }
    
    try {
      let currentNetwork = localStorage.getItem('currentNetwork');

      if(currentNetwork != null){
        let resourceAccess = auth.resource_access[currentNetwork];
        if(resourceAccess != undefined){
          const { roles } = resourceAccess;
          userRoles = roles.map(r => (r));
        }
      }

    } catch (error) {}
   
  }
 
  return userRoles;
};


export const hasPermission = (required_roles) => {

  return (dispatch, getState) => {
    if(!required_roles) {
      return true;
    }

    let hasPermissionFlag = false
    let userRolesName = getUserRolesName();
    userRolesName.map((roleName)=>{
      if(required_roles.includes(roleName)){
        hasPermissionFlag = true;
      }
    })


    return hasPermissionFlag;
  }
};



export const getUserSubscription = () => {
  const token = localStorage.getItem('access_token');
  return jwt_decode(token).apps;
}

export const SETUSER = 'user/SETUSER';

export const getUser = ({ id }, onSuccess = () => {}, onError = () => {}) => {
  return async (dispatch) => {
    axios(
      `${config.endpoints.base_url}v2/admin/users/${id}`
    ).then((resp) => {
      dispatch({ type: SETUSER, user: resp.data.data });
      return onSuccess(resp);
    }).catch((err) => {
      onError(err);
    });
  };
};

export const SETUSERPERMISSIONS = 'user/SETUSERPERMISSIONS';

export const getUserPermissions = (onSuccess = () => {}, onError = () => {}) => {
  return async (dispatch) => {

    const data = qs.stringify({
      'grant_type': 'urn:ietf:params:oauth:grant-type:uma-ticket',
      'audience': 'viana-services'
    });

    axios.post(
      `${config.auth.access_token_url}`,
      data
    ).then((resp) => {
      const permissions = jwt_decode(resp.data?.access_token)?.authorization?.permissions;
      if(permissions) {
        dispatch({ type: SETUSERPERMISSIONS, permissions });
      }
      return onSuccess(resp);
    }).catch((err) => {
      onError(err);
    });
  };
};

export const SETISEDITINGUSER = 'user/SETISEDITINGUSER';

export const editUser = ({ id, user, file }, onSuccess = () => {}, onError = () => {}) => {
  return async (dispatch) => {

    dispatch({ type: SETISEDITINGUSER, isEditingUser: true });

    if(file) {
      const formData = new FormData();
      formData.append('files', file);
      const fileRequest = await axios.post(
        `${config.endpoints.base_url}assets`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      );
      user.image = fileRequest.data.data[0].url;
    }

    axios.put(
      `${config.endpoints.base_url}v2/admin/users/${id}`,
      user
    ).then(() => {
      dispatch({ type: SETISEDITINGUSER, isEditingUser: false });
      return onSuccess();
    }).catch((err) => {
      onError(err);
    });
  };
};

export const LOGOUT = 'user/LOGOUT';

export const logoutUser = () => {
  return (dispatch) => {
    dispatch({ type: LOGOUT });
  };
};

export const SETUSERS = 'user/SETUSERS';
export const SETISLOADINGUSERS = 'user/SETISLOADINGUSERS'

export const getUsers = (onSuccess = () => {}, onError = () => {}) => {
  return async (dispatch) => {
    dispatch({ type: SETISLOADINGUSERS, isLoadingUsers: true });
    axios.get(
      `${config.endpoints.base_url}v2/admin/users`
    ).then((resp) => {
      dispatch({ type: SETUSERS, users: resp.data.data.rows });
      dispatch({ type: SETISLOADINGUSERS, isLoadingUsers: false });
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });
  };
};