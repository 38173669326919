import { SETISLOADINGSCENES, SETSCENES, SETISLOADINGCRITICALSCENE, SETCRITICALSCENE, SETISLOADINGREGIONS, SETREGIONS, SETISLOADINGSTAFFS, SETSTAFFS, SETISLOADINGINCIDENTS, SETISLOADINGSCENEEVENTS, SETSCENEEVENTS, SETSCENEVIEWDATETIME, REQUESTSCENEFRAMESTART, REQUESTSCENEFRAMEEND, SETSCENEVIEWTIMEEVENT } from '../actions/scenes';

const initialState = {
  isLoadingScenes: false,
  scenes: [],
  isLoadingCriticalScene: false,
  isLoadingRegions: false,
  regions: [],
  isLoadingIncidents: false,
  isLoadingStaffs: false,
  staffs: [],
  isLoadingSceneEvents: false,
  sceneEvents: [],
  sceneViewDate: null,
  sceneViewTime: null,
  criticalScene: {},
  capturingScenes: [],
  sceneViewTimeEvent: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SETISLOADINGSCENES:
      return {
        ...state,
        isLoadingScenes: action.isLoadingScenes
      };
    case SETSCENES:
      return {
        ...state,
        scenes: action.scenes
      };
    case SETISLOADINGCRITICALSCENE:
      return {
        ...state,
        isLoadingCriticalScene: action.isLoadingCriticalScene
      };
    case SETCRITICALSCENE:
      return {
        ...state,
        criticalScene: action.criticalScene
      };
    case SETISLOADINGREGIONS:
      return {
        ...state,
        isLoadingRegions: action.isLoadingRegions
      };
    case SETREGIONS:
      return {
        ...state,
        regions: action.regions
      };
    case SETISLOADINGINCIDENTS:
      return {
        ...state,
        isLoadingIncidents: action.isLoadingIncidents
      };
    case SETISLOADINGSTAFFS:
      return {
        ...state,
        isLoadingStaffs: action.isLoadingStaffs
      };
    case SETSTAFFS:
      return {
        ...state,
        staffs: action.staffs
      };
    case SETISLOADINGSCENEEVENTS:
      return {
        ...state,
        isLoadingSceneEvents: action.isLoadingSceneEvents
      };
    case SETSCENEEVENTS:
      return {
        ...state,
        sceneEvents: action.sceneEvents
      };
    case SETSCENEVIEWDATETIME:
      return {
        ...state,
        sceneViewDate: action.sceneViewDate,
        sceneViewTime: action.sceneViewTime
      };
    case REQUESTSCENEFRAMESTART:
      return {
        ...state
      };
      // return produce(state, draftState => {
      //   draftState.capturingScenes.push(parseInt(action.sceneID, 10));
      // });
    case REQUESTSCENEFRAMEEND:
      return {
        ...state
      };
      // return produce(state, draftState => {
      //   draftState.capturingScenes.filter((sceneItem) => {
      //     return sceneItem.id !== parseInt(action.sceneID, 10);
      //   });
      // });
    case SETSCENEVIEWTIMEEVENT:
      return {
        ...state,
        sceneViewTimeEvent: action.sceneViewTimeEvent
      }
    default:
      return state;
  }
};
