import { SETISLOADINGLOCATIONS, SETLOCATIONS, SETISADDINGLOCATION, SETISEDITINGLOCATION, SETISREMOVINGLOCATION } from '../actions/locations';

const initialState = {
  isLoadingLocations: false,
  locations: [],
  isAddingLocation: false,
  isEditingLocation: false,
  isRemovingLocation: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SETISLOADINGLOCATIONS:
      return {
        ...state,
        isLoadingLocations: action.isLoadingLocations
      }
    case SETLOCATIONS:
      return {
        ...state,
        locations: action.locations
      }
    case SETISADDINGLOCATION:
      return {
        ...state,
        isAddingLocation: action.isAddingLocation
      }
    case SETISEDITINGLOCATION:
      return {
        ...state,
        isEditingLocation: action.isEditingLocation
      }
    case SETISREMOVINGLOCATION:
      return {
        ...state,
        isRemovingLocation: action.isRemovingLocation
      }
    default:
      return state;
  }
}
