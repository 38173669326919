import axios from 'axios';
import * as config from '../config';

export const SETISLOADINGDEVICE = 'devicetype/SETISLOADINGDEVICE';
export const SETDEVICETYPE = 'devicetype/SETDEVICETYPE';

export const getDeviceType = (onSuccess = () => {}, onError = () => {}) => {
  return (dispatch) => {

    dispatch({ type: SETISLOADINGDEVICE, isLoadingDevice: true });

    axios(`${config.endpoints.base_url}devicetypes`).then((resp) => {
      dispatch({ type: SETISLOADINGDEVICE, isLoadingDevice: false });
      dispatch({ type: SETDEVICETYPE, devicetype: resp.data.data, activation_types: resp.data.data.activation_types });
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });
  };
};
