import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import { KeycloakProvider, useKeycloak } from '@react-keycloak/web';
import { PersistGate } from 'redux-persist/integration/react';
import axios from 'axios';
import { ApolloProvider } from '@apollo/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import configureStore, { history } from './store/store';
import { addIconLibrary } from './actions/icons';
import { resolveClient } from './graphql/resolver';

import './App.scss';

import keycloak, { auth } from './config';
import { getMyNetwork } from './actions/networks';
import { getBentoItems } from './actions/auth';
import jwt_decode from 'jwt-decode';

const keycloakProviderInitConfig = {
  // onLoad: 'check-sso',
  onLoad: 'login-required',
  checkLoginIframe: false
};

export const { persistor, store  } = configureStore();

const loading = () => <div className="animated fadeIn pt-3 text-center d-none"><i className="fas d-none fa-redo fa-spin" /></div>;

const AdminRoute = React.lazy(() => import('./containers/AdminLayout/AdminLayout'));
const Login = React.lazy(() => import('./views/Pages/Login/Login'));
const Logout = React.lazy(() => import('./views/Pages/Logout/Logout'));
const EndUserTerms = React.lazy(() => import('./views/Pages/EndUserTerms/EndUserTerms'));
const Register = React.lazy(() => import('./views/Pages/Register/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404/Page404'));
const Page401 = React.lazy(() => import('./views/Pages/Page401/Page401'));
const Page500 = React.lazy(() => import('./views/Pages/Page500/Page500'));
const Preloader = React.lazy(() => import('./views/Home/Preloader'));
const queryClient = new QueryClient();

addIconLibrary();

axios.interceptors.request.use(function (config) {
  try {
    if(!config.url.includes(auth.auth_url)){
      let currentNetwork = localStorage.getItem('currentNetwork');
      if(currentNetwork != null && currentNetwork != '' && currentNetwork != 'undefined'){
        config.headers.common['X-NETWORK-ID'] = currentNetwork;
      }
    }
  } catch (error) {}

  return config;
});


function AppRouter({ client }){
  const [, initialized] = useKeycloak();
  const [isNetworkSet, setIsNetworkSet ] = useState(false)

  useEffect(()=>{
    if(initialized){
      setNetwork()
    }

  },[initialized])

  const setNetwork = async () => {
    let currentNetwork = localStorage.getItem('currentNetwork');
    let recentNetworks = localStorage.getItem('recentNetworks');

    const urlParams = new URLSearchParams(window.location.search);
    let currentNetworkDetails = {}

    try {
      const networkParam = urlParams.get('networkParam');
      if(networkParam != null && networkParam != ""){
        currentNetwork = networkParam
        localStorage.setItem('currentNetwork', networkParam)
      }

       //setup recent network
      try {
        const accessToken = localStorage.getItem('access_token');
        let auth = null
        if (accessToken !== undefined) {
          try {
            auth = jwt_decode(accessToken);
          }
          catch (e) {}
        }
        let recentNetworksParsed = JSON.parse(recentNetworks)
        if(recentNetworksParsed?.user == undefined ||  recentNetworksParsed?.recentNetworks == undefined){
          localStorage.setItem('recentNetworks', JSON.stringify({user:auth.email, recentNetworks:[]}));
        } else {
          if(recentNetworksParsed?.user != auth.email){
            localStorage.setItem('recentNetworks', JSON.stringify({user:auth.email, recentNetworks:[]}));
          }
        }
      } catch (error) {
        localStorage.setItem('recentNetworks', JSON.stringify({user:auth.email, recentNetworks:[]}));
      }

      const getMyNetworkResp = await getMyNetwork();
      let doesExist = false;
      getMyNetworkResp.data.map((network)=>{
        if(currentNetwork == network.name){
          doesExist = true;
          currentNetworkDetails = network;
        }
      });

      if(!doesExist){
        try {
          localStorage.setItem('currentNetwork', getMyNetworkResp.data[0].name)
          currentNetworkDetails = getMyNetworkResp.data[0];
        } catch (error) {}
      }

      // check if has coatro app
      let hasCoatro = false;
      let bentoItems = getBentoItems(currentNetworkDetails)
      bentoItems.map((item)=>{
        if(item.name == "Coatro"){
          hasCoatro = true
        }
      })

      if(!hasCoatro){
        setIsNetworkSet(true)
        return history.replace('/401');
      }
      setIsNetworkSet(true)
    } catch (error) {
      setIsNetworkSet(true)
    }

  };

  if (!initialized || !isNetworkSet) {
    return <div />;
  }


  return (
    <ApolloProvider client={client}>
      <QueryClientProvider client={queryClient}>
        <ConnectedRouter history={history}>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
              <Route exact path="/logout" name="Logout Page" render={props => <Logout {...props} />} />
              <Route exact path="/enduserterms" name="End User Terms" render={props => <EndUserTerms {...props} />} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
              <Route exact path="/401" name="Page 401" render={props => <Page401 {...props} />} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
              <Route exact path="/home" name="Viana" render={props => <Preloader {...props} />} />
              <Route path="/" name="Home" render={props => <AdminRoute {...props} />} />
            </Switch>
          </React.Suspense>
        </ConnectedRouter>
      </QueryClientProvider>
    </ApolloProvider>
  );
}

function App(){
  const [client, setClient] = useState(null);

  return (
    <KeycloakProvider
      keycloak={keycloak}
      initConfig={keycloakProviderInitConfig}
      onEvent={(event, error) => {
        switch(event) {
          case 'onAuthSuccess':
            break;
          case 'onAuthLogout':
            break;
          case 'onTokenExpired':
            keycloak.updateToken(5).then(() => {
              // console.log('successfully get a new token', keycloak.token);
            }).catch(() => {
              history.push('/login');
              keycloak.logout();
            });
            break;
          case 'onInitError':
            break;
          case 'onAuthError':
            setClient(true);
            break;
          default:
            break;
        }
      }}
      onTokens={(tokens) => {
        localStorage.setItem('access_token', tokens.token);
        axios.defaults.headers.common.Authorization = `Bearer ${tokens.token}`;
        setClient(resolveClient(tokens.token, keycloak));
      }}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {client && <AppRouter client={client} />}
        </PersistGate>
      </Provider>
    </KeycloakProvider>
  );
}

export default App;

