import plural from 'plural';
import { replace, isEqual, head, filter, isNil, capitalize, split, gt, remove, gte, indexOf } from 'lodash';
import { viana_urls, coatro_urls, } from './urls';
import moment from 'moment';

const ELLIPSISLIMIT = 50;


const pluralize = (value: number, word: string) => {
  return (value > 1) ? plural(word) : plural(word, 1);
}

const replaceStringTemplate = (text: string, replacement) => {
  return replace(text, '~~', replacement)
}

const ellipsis = (string: string, limit: number) => {
  return string.substr(0, limit) + "...";
}

const checkEllipsis = (value: string) => {
  return (value.length > ELLIPSISLIMIT) ? ellipsis(value, ELLIPSISLIMIT) : value;
}

const getDeviceName = (data, id) => {
  return head(filter(data, { 'id': parseInt(id) }))?.name;
}

const bentoLinks = (portal: string): string => {
  let endpoints;
  if (isEqual(portal, 'viana')) {
    endpoints = process.env.REACT_APP_VIANA_ENV === 'production'
      ? viana_urls.production :
        process.env.REACT_APP_VIANA_ENV === 'production2'
        ? viana_urls.production
          : process.env.REACT_APP_VIANA_ENV === 'preproduction'
            ? viana_urls.preproduction
            : viana_urls.staging;
  }
  else {
    endpoints = process.env.REACT_APP_VIANA_ENV === 'production'
      ? coatro_urls.production :
        process.env.REACT_APP_VIANA_ENV === 'production2'
        ? coatro_urls.production
        : process.env.REACT_APP_VIANA_ENV === 'preproduction'
          ? coatro_urls.preproduction
          : coatro_urls.staging;
  }

  return endpoints;
}

const dateFormat = (data) => {
  return moment(data).format('DD-MMM-YYYY');
}

const dateFormatReadable = (data) => {
  return moment(data).format('MMMM DD, yyyy');
}

const _filter = (data, object) => {
  return head(filter(data, object));
}

const convertToString = (data) => {
  return parseInt(data).toString();
}

const isEndDateIndifinite = (data) => {
  return (isNil(data)) ? ` -` : ` ${dateFormatReadable(data)}`;
}

const runningTime = (all_day, start_time, end_time) => {

  let start_hour, start_min, end_hour, end_min;

  if (isEqual(all_day, true)) {
    return `All day`;
  }
  else {
    if (!isNil(start_time)) {
      [start_hour, start_min] = start_time.split(':');
    }
    if (!isNil(end_time)) {
      [end_hour, end_min] = end_time.split(':');
    }

    if (
      !isNil(start_hour) &&
      !isNil(start_min) &&
      !isNil(end_hour) &&
      !isNil(end_min)) {
      return `${moment().hours(start_hour).minutes(start_min).format("HH:mm")} - ${moment().hours(end_hour).minutes(end_min).format("HH:mm")}`
    }
  }

}

const runningDays = (data) => {
  return (!isEqual(data?.type, 'select_days')) ? capitalize(data?.type) : ` ${data?.days.join(" ")}`;
}

const isEvery = (data) => {
  return (isEqual(data?.type, 'everyday')) ? '' : `every`;
}

const filterUserSiteDevices = (auth, devices) => {
  if (!isNil(auth.user.attributes?.sites)) {
    if (gt(auth.user.attributes?.sites.length, 0)) {
      devices = remove(devices, (n) => {
        return gte(indexOf(auth.user.attributes?.sites, n?.site_id.toString()), 0);
      });
    }
  }
  return devices;
}




export {
  pluralize,
  replaceStringTemplate,
  ellipsis,
  checkEllipsis,
  bentoLinks,
  getDeviceName,
  dateFormat,
  _filter,
  convertToString,
  isEndDateIndifinite,
  runningTime,
  runningDays,
  isEvery,
  filterUserSiteDevices,
  dateFormatReadable
};
