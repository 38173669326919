import { SET_USECASES } from '../actions/useCases';

const initialState = {
  useCases: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USECASES: {
      return { ...state, useCases: action.useCases };
    }
    default:
      return state;
  }
};
