import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import createRootReducer from '../reducers';
import customMiddlewares from '../middlewares/index';
export const history = createBrowserHistory();

const initialState = {};
const enhancers = [];
const middleware = [
  thunk,
  routerMiddleware(history),
  ...customMiddlewares
];
const persistConfig = {
  key: 'viana',
  storage,
}

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

export default function configureStore() {
  const persistedReducer = persistReducer(persistConfig, createRootReducer(history)); // root reducer with router state
  const store = createStore(
    // createRootReducer(history), // root reducer with router state
    persistedReducer,
    initialState,
    compose(
      applyMiddleware(
        ...middleware
      ),
      ...enhancers
    ),
  )
  const persistor = persistStore(store)

  return { store, persistor }
}
