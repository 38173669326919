import Keycloak from 'keycloak-js';

const authDev = {
  auth_url: 'https://id.skunkworks.ai/auth',
  realm: 'staging',
  access_token_url: 'https://id.skunkworks.ai/auth/realms/staging/protocol/openid-connect/token',
  client_id: 'viana-portal',
  client_secret: 'c2f34191-5f73-4699-91d2-4283fd97e81c',
  scope: 'openid user',
  powerBIReportID: 'e13cb171-aacd-4e9c-b5d9-7a2f24926b10',
  superset: {
    baseUrl: 'https://superset-dev.internal.viana.ai',
    coatro: '82ac9969-25dd-4979-95a4-88d7c5844300',
    coeffe: '78986180-b7bc-43cc-bad8-08d9bcb4b0ef',
    trafficMeasurement: 'f9abf10e-c5df-420b-be06-4ce3b2712651',
    coffeeVehicle: 'fc1c74d3-21b3-4352-bfd2-b53006b83436'
  }
};

const endpointsDev = {
  environment: 'dev',
  base_url: 'https://dev.services.api.viana.ai/',
  base_url_insights: 'https://dev.insights.api.viana.ai/',
  admin_url: 'https://dev.admin.internal.viana.ai/'
};

const authStaging = {
  auth_url: 'https://id.skunkworks.ai/auth',
  realm: 'staging',
  access_token_url: 'https://id.skunkworks.ai/auth/realms/staging/protocol/openid-connect/token',
  client_id: 'viana-portal',
  client_secret: 'c2f34191-5f73-4699-91d2-4283fd97e81c',
  scope: 'openid user',
  powerBIReportID: 'e13cb171-aacd-4e9c-b5d9-7a2f24926b10',
  superset: {
    baseUrl: 'https://superset-staging.internal.viana.ai',
    coatro: 'cb5c5e0b-be3f-41b9-87e6-d17ff520a19b',
    coeffe: 'd9ea54e0-588b-4dce-a46d-73889f37ec29',
    trafficMeasurement: '98bdfdd8-380f-4973-af4a-6af45c4a3520',
    coffeeVehicle: 'd477a229-b7b0-4409-a8d5-69e7895bcfdf'
  }
};

const endpointsStaging = {
  environment: 'staging',
  base_url: 'https://staging.services.api.viana.ai/',
  base_url_insights: 'https://staging.insights.api.viana.ai/',
  admin_url: 'https://staging.admin.internal.viana.ai/',
  viana_url: 'https://staging.portal.internal.viana.ai/',
};

const endpointsStaging2 = {
  environment: 'staging2',
  base_url: 'https://viana-services-staging-copy3.azurewebsites.net/',
  base_url_insights: 'https://viana-insights-staging-auspost.azurewebsites.net/',
  admin_url: 'https://admin.node16poc.internal.viana.ai/',
  viana_url: 'https://staging4.portal.internal.viana.ai//',
  assets_url: 'https://assets.api.viana.ai/'
};

const authPreproduction = {
  auth_url: 'https://id.skunkworks.ai/auth',
  realm: 'preprod',
  access_token_url: 'https://id.skunkworks.ai/auth/realms/preprod/protocol/openid-connect/token',
  client_id: 'viana-portal',
  client_secret: '864a614e-590f-4abf-8cf2-ec3b7ebecaab',
  scope: 'openid user',
  powerBIReportID: '6316bc5e-4326-46a5-8151-70566e322041',
  superset: {
    baseUrl: 'https://superset-ppd.internal.viana.ai',
    coatro: 'cdf80baa-55a9-4f3a-b801-34cc90b82720',
    coeffe: '042f9dd2-ad1a-4c4d-be9d-2dfe36892643',
    trafficMeasurement: '2f719499-0d2d-49e8-85dd-021ee3de1648'
  }
};

const endpointsPreproduction = {
  environment: 'preproduction',
  base_url: 'https://ppd.services.api.viana.ai/',
  base_url_insights: 'https://ppd.insights.api.viana.ai/',
  admin_url: 'https://prep.admin.internal.viana.ai/',
  viana_url: 'https://prep.portal.internal.viana.ai/'
};

const authProduction = {
  auth_url: 'https://id.viana.ai/auth',
  realm: 'production',
  access_token_url: 'https://id.viana.ai/auth/realms/production/protocol/openid-connect/token',
  client_id: 'viana-portal',
  client_secret: '63b58278-28c8-43a8-abc6-7698c0146c90',
  scope: 'openid user',
  powerBIReportID: 'fe0fd6dd-1a69-4964-86bb-35f103aa6577',
  superset: {
    baseUrl: 'https://cortex2.viana.ai',
    coatro: '55b73c53-e5dc-4940-8e9e-91a0b2861a41',
    coeffe: '344a119c-0018-416e-bf61-cdcc7abd2249',
    trafficMeasurement: '6f40e9dc-a93e-4795-bf87-6e949b65c04e'
  }
};

const endpointsProduction2 = {
  environment: 'production',
  base_url: 'https://viana-services-copy.azurewebsites.net/',
  base_url_insights: 'https://viana-insights-production-copy2.azurewebsites.net/',
  admin_url: 'https://admin.viana.ai/',
  viana_url: 'https://admin.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/',
  sentry_dsn: ''
}
const endpointsProduction = {
  environment: 'production',
  base_url: 'https://services.api.viana.ai/',
  base_url_insights: 'https://insights.api.viana.ai/',
  viana_url: 'https://portal.viana.ai/',
  admin_url: 'https://admin.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/',
  sentry_dsn: ''
};

export const auth = process.env.REACT_APP_VIANA_ENV === 'production'
  ? authProduction
  : process.env.REACT_APP_VIANA_ENV === 'production2'
    ?  authProduction
    : process.env.REACT_APP_VIANA_ENV === 'preproduction'
      ? authPreproduction
      : process.env.REACT_APP_VIANA_ENV === 'dev'
        ? authDev
        : authStaging;

export let endpoints;
if (process.env.REACT_APP_VIANA_ENV === 'production') {
  endpoints = endpointsProduction;
} else if (process.env.REACT_APP_VIANA_ENV === 'production2') {
  endpoints = endpointsProduction2;
} else if (process.env.REACT_APP_VIANA_ENV === 'preproduction') {
  endpoints = endpointsPreproduction;
} else if (process.env.REACT_APP_VIANA_ENV === 'staging2') {
  endpoints = endpointsStaging2;
} else if (process.env.REACT_APP_VIANA_ENV === 'dev') {
  endpoints = endpointsDev;
} else {
  endpoints = endpointsStaging;
}

const initOptions = {
  url: auth.auth_url, realm: auth.realm, clientId: auth.client_id, onLoad: 'login-required'
};

const keycloak = new Keycloak(initOptions);
export default keycloak;
