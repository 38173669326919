const viana_urls = {
  production: 'https://portal.viana.ai',
  preproduction: 'https://prep.portal.internal.viana.ai',
  staging: 'https://staging.portal.internal.viana.ai'
};

const coatro_urls = {
  production: 'https://coatro.viana.ai',
  preproduction: 'http://prep.coatro.viana.ai',
  staging: 'https://staging.coatro.viana.ai'
};

export { viana_urls, coatro_urls };
