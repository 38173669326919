import axios from 'axios';
import * as config from '../config';
import { GENERALERROR } from './signagevendors';

export const ADDTARGETSEGMENT = 'targetingstrategy/ADDTARGETSEGMENT';
export const REMOVETARGETSEGMENT = 'targetingstrategy/REMOVETARGETSEGMENT';
export const ADDTARGETSTRATEGY = 'targetingstrategy/ADDTARGETSTRATEGY';
export const SETTARGETSTRATEGY = 'targetingstrategy/SETTARGETSTRATEGY';
export const SETISLOADINGSTRATEGY = 'targetingstrategy/SETISLOADINGSTRATEGY';
export const DELETETARGETSEGMENT = 'targetingstrategy/DELETETARGETSEGMENT';
export const ARCHIVETARGETSTRATEGY = 'targetingstrategy/ARCHIVETARGETSTRATEGY';
export const SETEDITTARGETSTRATEGY = 'targetingstrategy/SETEDITTARGETSTRATEGY';
export const UPDATETARGETSTRATEGY = 'targetingstrategy/UPDATETARGETSTRATEGY';
export const SUCCESSARCHIVESTRATEGY = 'targetingstrategy/SUCCESSARCHIVESTRATEGY';
export const SETTARGETSEGMENTS = 'targetingstrategy/SETTARGETSEGMENTS';

export const addTargetSegment = (
  data: any,
  isCreate: boolean = true
) => {
  return async (dispatch, getState): Promise<void> => {
    dispatch({ type: ADDTARGETSEGMENT, targetsegment: data, isCreate });
  };
};

export const setTargetSegments = (
  data: any,
) => {
  return async (dispatch, getState): Promise<void> => {
    dispatch({ type: SETTARGETSEGMENTS, targetsegments: data });
  };
};

export const deleteTargetSegment = (
  data: any
) => {
  return async (dispatch, getState): Promise<void> => {
    dispatch({ type: DELETETARGETSEGMENT, targetsegment: data });
  };
};

export const setTargetStrategy = (
  targetstrategy: any,
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) => {
  return async (dispatch, getState): Promise<void> => {
    try {
      const { data } = await axios.post(`${config.endpoints.base_url}targetstrategies`, targetstrategy);
      dispatch({ type: REMOVETARGETSEGMENT });
      dispatch({ type: ADDTARGETSTRATEGY, targetstrategy: data.data });
      if (onSuccess) {
        onSuccess(data.status);
      }

    } catch (error) {
      const response = (error as any).response; // Explicitly cast error to any to access response object
      if (onError) {
        onError(response.data.message);
      }
    }
  };
}

export const getTargetStrategies = (
  onSuccess?: (data: Array<any>) => void,
  onError?: () => void
) => {
  return async (dispatch, getState): Promise<void> => {
    try {
      dispatch({ type: SETISLOADINGSTRATEGY, isLoadingStrategies: true });
      const { data } = await axios(`${config.endpoints.base_url}targetstrategies`);

      if (onSuccess) {
        onSuccess(data.data.rows);
        dispatch({ type: SETISLOADINGSTRATEGY, isLoadingStrategies: false });
        dispatch({ type: SETTARGETSTRATEGY, targetstrategies: data.data.rows });
        dispatch({ type: REMOVETARGETSEGMENT });
      }


    } catch (error) {
      dispatch({ type: SETISLOADINGSTRATEGY, isLoadingStrategies: false });
      dispatch({ type: GENERALERROR });
      if (onError) {
        onError();
      }
    }
  };
};


export const archiveTargetStrategy = (
  targetstrategy: any,
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) => {
  return async (dispatch, getState): Promise<void> => {
    try {
      const { data } = await axios.delete(`${config.endpoints.base_url}targetstrategies/${targetstrategy.id}`, targetstrategy);
      if (onSuccess) {
        dispatch({ type: ARCHIVETARGETSTRATEGY, targetstrategy });
        onSuccess(data.status);
      }

    } catch (error) {
      const response = (error as any).response; // Explicitly cast error to any to access response object
      if (onError) {
        onError(response.data.message);
      }
    }
  };
}

export const getTargetStrategy = (
  id: number,
  onSuccess?: (data: Array<any>) => void,
  onError?: () => void
) => {
  return async (dispatch, getState): Promise<void> => {
    try {
      dispatch({ type: SETISLOADINGSTRATEGY, isLoadingStrategies: true });
      const { data } = await axios(`${config.endpoints.base_url}targetstrategies/${id}`);
      let target_strategy = data.data;
      target_strategy.target_strategy_segments = target_strategy.target_strategy_segments.sort((a, b) => a.priority - b.priority);
      if (onSuccess) {
        onSuccess(target_strategy);
        dispatch({ type: SETISLOADINGSTRATEGY, isLoadingStrategies: false });
        dispatch({ type: SETEDITTARGETSTRATEGY, targetstrategy: target_strategy });
      }
    } catch (error) {
      dispatch({ type: GENERALERROR });
      if (onError) {
        onError();
      }
    }
  };
};

export const updateTargetStrategy = (
  id: number,
  targetstrategy: any,
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) => {
  return async (dispatch, getState): Promise<void> => {
    try {
      const { data } = await axios.put(`${config.endpoints.base_url}targetstrategies/${id}`, targetstrategy);
      dispatch({ type: UPDATETARGETSTRATEGY, targetstrategy: data.data });
      if (onSuccess) {
        onSuccess(data.status);
      }

    } catch (error) {
      const response = (error as any).response; // Explicitly cast error to any to access response object
      if (onError) {
        onError(response.data.message);
      }
    }
  };
}

export const removeTargetSegment = (
) => {
  return async (dispatch, getState): Promise<void> => {
    dispatch({ type: REMOVETARGETSEGMENT });
  };
};
