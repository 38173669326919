import {
  ADDTARGETSEGMENT,
  REMOVETARGETSEGMENT,
  ADDTARGETSTRATEGY,
  SETTARGETSTRATEGY,
  SETISLOADINGSTRATEGY,
  DELETETARGETSEGMENT,
  ARCHIVETARGETSTRATEGY,
  SETEDITTARGETSTRATEGY,
  UPDATETARGETSTRATEGY,
  SETTARGETSEGMENTS
} from '../actions/targetingstrategy';
import { produce } from 'immer';
import * as _ from 'lodash';

let order;
const initialState = {
  isLoadingStrategies: false,
  targetsegments: [],
  targetstrategies: [],
  targetstrategy: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADDTARGETSEGMENT:
      return produce(state, draftState => {
        draftState.targetsegments.push(action.targetsegment);
      });
    case SETTARGETSEGMENTS:
      order = 0;
      return { ...state, targetsegments: action.targetsegments.map(segment => { order++; return { ...segment, order: order }; }) };
    case REMOVETARGETSEGMENT:
      return produce(state, draftState => {
        draftState.targetsegments = [];
      });
    case DELETETARGETSEGMENT:
      order = 0;
      let filtered_segments = state.targetsegments.filter(item => action.targetsegment !== item);

      return { ...state, targetsegments: filtered_segments.map(segment => { order++; return { ...segment, order: order }; }) };
    case ADDTARGETSTRATEGY:
      return produce(state, draftState => {
        draftState.targetstrategies.push(action.targetstrategy);
      });
    case SETTARGETSTRATEGY:
      return { ...state, targetstrategies: action.targetstrategies }
    case ARCHIVETARGETSTRATEGY:
      const { targetstrategy } = action;
      return { ...state, targetstrategies: _.pullAllBy(state.targetstrategies, [{ 'id': targetstrategy.id }], 'id') };
    case SETISLOADINGSTRATEGY:
      return produce(state, draftState => {
        draftState.isLoadingStrategies = action.isLoadingStrategies;
      });
    case SETEDITTARGETSTRATEGY:
      return { ...state, targetstrategy: action.targetstrategy }
    case UPDATETARGETSTRATEGY:
      state.targetstrategies[state.targetstrategies.indexOf(state.targetstrategies.find((sd) => sd.id === action.targetstrategy.id))] = action.targetstrategy;
      return state
    default:
      return state;
  }
};
