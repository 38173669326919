import { SETISLOADINGFEEDERS, SETFEEDERS, SETISEDITINGFEEDER } from '../actions/feeders';

const initialState = {
  isLoadingFeeders: false,
  feeders: [],
  isEditingFeeder: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SETISLOADINGFEEDERS:
      return {
        ...state,
        isLoadingFeeders: action.isLoadingFeeders
      }
    case SETFEEDERS:
      return {
        ...state,
        feeders: action.feeders
      }
    case SETISEDITINGFEEDER:
      return {
        ...state,
        isEditingFeeder: action.isEditingFeeder
      }
    default:
      return state;
  }
}
