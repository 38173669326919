import { ADDMODEL, ADDLIST } from '../actions/blender';

const initialState = {
  list: [],
  models: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ADDMODEL:
      return {
        ...state,
        models: action.models
      }
    case ADDLIST:
      return {
        ...state,
        list: action.list
      }
    default:
      return state;
  }
}
